<template>
  <div>
    <div class="pt-[1.25rem] flex justify-between gap-x-1">
      <div class="flex gap-x-1">
        <p class="text-sm pl-3.5 font-poppins leading-[0.938rem] font-bold">Campaign</p>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 13 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z" fill="#3C4549"/></svg>
      </div>
      <p v-if="checkURLState() === 'success_state'" class="text leading-[0.938rem]">{{ computeIframeErrorPrompt() ? 'Summary page applied to your shortened URL' : 'Cta applied to the link'}}
        <button id="preview-button"
                class="text leading-[0.938rem] !text-[#2961D2] ml-[0.3rem] font-semibold underline"
                type="button">
          See Preview
        </button>
      </p>
    </div>

    <b-dropdown ref="campaigns_dropdown_quick_link" right class="w-full !pt-3 dropdown-menu-right hide_dropdown_caret default_style_dropdown"
                :no-caret="true">
      <div @click="fetchCampaignOnDropdown" :class="checkURLState()" class="w-full dropdown_header bg-white h-[2.875rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center"
           slot="button-content"
           data-cy="select-camp">
        <div v-tooltip="limitTextLength(getSelectedCampaign(),40)" class="">
          <p class="text !text-[#3C4549]">{{ limitTextLength(getSelectedCampaign(), 40) }}</p>
        </div>
        <span class="arrow_icon flex items-center ml-auto">
        <Loader v-if="getLinksLoaders.link_preview" color="text-[#2560d7] mr-1"></Loader>
        <InputFieldMessage v-if="getSaveLink.url.includes('http://')" type="warning" message="Links with 'http' might not work. Recommended: https links"></InputFieldMessage>
        <InputFieldMessage v-else-if="!metaFetchStatus" type="danger" :message="`URL can't be validated. Please enter valid URL.`"></InputFieldMessage>
          <InputFieldMessage v-else-if="checkURLState() === 'warning_state' && !isFallbackCtaEnabled() && getLinks.previewErrorMessage === 'notAllowed' && !isBridgeCTACampaign()" type="warning" :message="computeIframeMessage()"></InputFieldMessage>
          <i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i>
      </span>
      </div>
      <ul id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')"
          class="clear inner w-full !px-0 !pb-2 !py-1">
        <template
          v-if="(getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7) || getCampaigns.search">
          <div class="mx-[1.5rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
            <input placeholder="Search for campaign"
                   type="text"
                   class="w-full !h-[2.2rem] !border-none px-1"
                   :value="getCampaigns.search"
                   @input="debounceCampaignDropdownSearch"
                   data-cy="search-camp"> <i
            class="fal fa-search"></i>
          </div>
          <hr>
        </template>
        <template v-if="getCampaignsList && getCampaignsList.length">
          <li class="cursor-pointer group hover:bg-[#F4F6FA] px-6 py-[0.75rem] text-[0.875rem] text-[#3C4549] flex justify-between font-poppins items-center" @click="$refs.campaigns_dropdown_quick_link.visible = false"
              v-for="(cta, index) in getComponentCampaignList"
              @click.prevent="selectCampaignForLink(cta),$refs.campaigns_dropdown_quick_link.hide(true)"
              v-if="cta.name" :key="index" data-cy="camps">

            {{ limitTextLength(cta.name, 25) }}

          </li>
          <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
        </template>
        <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
          <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
        </li>
        <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length"
            class="text pt-1 text-center">{{ searchFiltersMessages('').search_field_dropdown }}
        </li>
      </ul>
    </b-dropdown>
  </div>
 </template>
<script>
import { mapGetters } from 'vuex'
import { campaignTypes, linkTypes } from '@/state/modules/mutation-types'
import InputFieldMessage from '@/ui/ui-kit/v2/InputFieldMessage.vue'

export default {
  props: [
    'metaFetchStatus'
  ],
  computed: {
    ...mapGetters([
      'getCampaigns',
      'getCampaignsList',
      'getSaveLink',
      'getDefaultDomainEnv',
      'getDomains',
      'getLinksLoaders',
      'getLinks'
    ])
  },
  data() {
    return {

    }
  },
  components: {
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue')
  },
  methods: {
    /**
     * computing the error message for iframe
     * @returns {string}
     */
    computeIframeMessage() {
      if(!this.metaFetchStatus) {
        return `URL can't be validated. Please enter valid URL.`
      }
      return this.computeIframeErrorPrompt() ? 'Summary page applied to your shortened URL.' : 'Your link cannot be shortened because the destination URL does not allow iFrame for the CTA campaign. Please enable the summary page as a fallback from Miscellaneous Settings.'
    },
    checkURLState () {
      const url = this.getSaveLink.url ? this.getSaveLink.url.trim() : ''
      if (url.length > 0) {
        this.getSaveLink.url = this.getSaveLink.url.trim()
        if (this.isBridgeCTACampaign()) {
          return 'success_state'
        } else if (this.computeIframeErrorPrompt()) {
          if(!this.metaFetchStatus) return 'danger_state'
          return 'success_state'
        } else if (!this.getLinksLoaders.link_preview && !this.getLinks.previewStatus && this.isTypeCTA(this.getSaveLink.call_to_action_id)) {
          return 'warning_state'
        } else if (!this.getLinksLoaders.link_preview && this.getLinks.previewStatus && this.isTypeCTA(this.getSaveLink.call_to_action_id)) {
          return 'success_state'
        }
      }
    },
    /**
     * computing the iframe error prompt
     * @returns {false|boolean|*}
     */
    computeIframeErrorPrompt() {
      return this.isFallbackCtaEnabled() && this.getLinks.previewErrorMessage === 'notAllowed' && this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign()
    },
    /**
     *  getting the selected campaign name
     * @returns {string|*|string}
     */
    getSelectedCampaign () {
      // if creating new rss so cta_id is null
      if (!this.getSaveLink.call_to_action_id) {
        return 'Select your Campaign'
      }

      // while editing rss so ge campaign name from list if not then get from rss feed list
      const campaignName = this.getCampaignName(this.getSaveLink.call_to_action_id)

      if (!campaignName) {
        const campaign = this.getLinks.items.find(item => this.getSaveLink.call_to_action_id === item.call_to_action_id)
        return campaign && campaign.cta && campaign.cta.name ? campaign.cta.name : 'Select your Campaign'
      }

      // is selected campaign exists then simple returs its name
      return campaignName && campaignName.name ? campaignName.name : 'Select your Campaign'

    },
    selectCampaignForLink (campaign) {
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, campaign)
      const httpPrefix = 'http://'
      const httpsPrefix = 'https://'
      if (this.getSaveLink.url.includes(httpPrefix)) {
        this.getSaveLink.url = httpsPrefix + this.getSaveLink.url.substring(httpPrefix.length)
      }
      this.$store.commit(linkTypes.SET_LINK_CAMPAIGN_ID, campaign._id)
      // this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, JSON.parse(JSON.stringify(campaign)))
      this.$set(this.getSaveLink, 'cta', campaign)
      let domainObj = this.getDomains.items.find((obj => {
        return obj._id === campaign.domainId
      }))

      if (this.oldDomainObj && this.getSaveLink.is_default_domain && !domainObj && (this.oldDomainObj.url !== this.getDefaultDomainEnv.link.url)) {
        return this.getSaveLink.domain = this.oldDomainObj
      }

      if (!domainObj && this.getDefaultDomainEnv) {
        this.getSaveLink.domain = {
          netloc: this.getDefaultDomainEnv.link.netloc,
          registered_domain: this.getDefaultDomainEnv.link.registered_domain,
          scheme: this.getDefaultDomainEnv.link.scheme,
          url: this.getDefaultDomainEnv.link.url
        }
        return
      }
      if (domainObj && domainObj.url.substr(-1) !== '/') {
        domainObj.url = domainObj.url + '/'
      }

      this.getSaveLink.domain = domainObj
    },
    /**
     * validating the URL
     * @returns {boolean}
     */
    validateCampaignURL () {
      // if fallback enabled and no link meta fetched
      if(this.computeIframeErrorPrompt() && !this.metaFetchStatus) {
        this.$store.dispatch('toastNotification', {message: `URL can't be validated. Please enter valid URL.`, type: 'error'})
        return false
      }
      return true
    }
  }
}
</script>
<style lang="less">
.warning_state {
  border: 1px solid #F4B740 !important;
  background-color: #FFFEF9 !important;
}
.danger_state {
  border: 1px solid #C13A52 !important;
  background-color: #FFFCFC !important;
}
</style>
